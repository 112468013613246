import { useState } from 'react'
import Icon from 'src/components/ui/Icon/Icon'

import { useFooter } from './useFooter'

type LinkData = {
  text: string
  link: string
}

type ServicesHelpAboutUsProps = {
  items: LinkData[]
  itemSectionTitle: string
}
export const ServicesHelpAboutUs: React.FC<ServicesHelpAboutUsProps> = (
  props
) => {
  const { items, itemSectionTitle } = props
  const [sectionOpen, setSectionOpen] = useState(false)
  const { sendEventRedirect } = useFooter()

  return (
    <>
      <div className="flex flex-col gap-4">
        <p
          data-testid="footerSectionsTitle"
          className="flex flex-row text-restructure-primary-inverted justify-between
          restructure-maxMobile:tracking-normal
          restructure-maxMobile:normal-case
          restructure-maxTablet:normal-case
          mobile-heading-title4
          tablet-mobile-heading-title4
          desktop-overline-medium
        "
        >
          {itemSectionTitle}
          {sectionOpen ? (
            <button
              data-testid="footerSectionsIconMinus"
              onClick={() => setSectionOpen((state) => !state)}
            >
              <Icon
                className="flex restructure-small-desktop:hidden"
                aria-label={`Botão para minimizar as informações da seção ${itemSectionTitle}`}
                name="Minus"
                width={24}
                height={24}
                fill="#fff"
              />
            </button>
          ) : (
            <button
              data-testid="footerSectionsIconPlus"
              onClick={() => setSectionOpen((state) => !state)}
            >
              <Icon
                className="flex restructure-small-desktop:hidden"
                aria-label={`Botão para maximizar as informações da seção ${itemSectionTitle}`}
                name="Plus"
                width={24}
                height={24}
                fill="#fff"
              />
            </button>
          )}
        </p>

        <div
          className={`restructure-mobile: ${
            sectionOpen ? 'flex' : 'hidden'
          } restructure-small-desktop:flex flex-col gap-2 items-start w-[166px]`}
        >
          {items?.map((item, index) => (
            <div
              className="mobile-body-regular-text3 text-restructure-white
              mobile-body-regular-text3
              tablet-body-regular-text3
              desktop-body-regular-text3 "
              key={index}
            >
              <a
                data-testid="footerSectionsItem"
                onClick={() => sendEventRedirect(item.text)}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                aria-label={`Link de redirecionamento para o tópico ${item.text}`}
              >
                {item.text}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
