module.exports = {
  // Ecommerce Platform
  platform: 'vtex',

  // Platform specific configs for API
  api: {
    storeId: 'decathlonstore',
    workspace: 'cms',
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  proxyUrl: {
    store: 'decathlonstore.vtexcommercestable',
  },

  // Default channel
  channel: '{"salesChannel":"3"}',
  locale: 'pt-BR',

  // Production URLs
  storeUrl: 'https://store.decathlon.com.br',
  secureSubdomain: 'https://secure.decathlon.com.br',
  checkoutUrl: 'https://secure.decathlon.com.br/checkout',
  loginUrl: 'https://secure.decathlon.com.br/api/io/login',
  signUpUrl: 'https://secure.decathlon.com.br/cadastro',
  accountUrl: 'https://store.decathlon.com.br/account',
  apiReviewDecathlon:
    'https://decathlonstore.myvtex.com/api/io/_v/graphql/private/v1',
  faststoreApi: 'https://decathlonstore.vtex.app/api',

  // Search URLs
  decathlonSearch: 'https://decathlonstore.myvtex.com/_v/api/decathlon-search',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      pdp: '/mochila-de-trilha-nh100-20-litros-101427-492434/p',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      pages: {
        home: '/',
        pdp: '/meia-termica-cano-alto-adulto-de-ski-e-snowboard-638256727/p',
        collection: '/ciclismo/',
        search: '/pesquisa?q=tenis&sort=score_desc&page=0',
      },
    },
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId: 'GTM-T777CVM',
  },

  token: {
    storeId: 'decathlonstoreapp',
  },

  logRocket: {
    appId: 'izcqja/corebiz-decathlon',
  },

  builder: {
    apiKey: '1a46d27fc8364e2f8b22a0b3b035f833',
  },

  doris: {
    publicApiKey: 'b1aaf4e4-0aa3-4ddc-bc1b-7a2559be0f2a',
  },
}
