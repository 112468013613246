import type { ProductItem, Product } from 'src/components/product/types'

import { makeItem } from './common/makeItem'
import type { ViewItemListEvent } from './types/viewItemListEvent'

export const makeViewItemListEvent = (
  items: Array<{
    product: Product
    productItem: ProductItem
    listName: string
    listId: string
  }>
): ViewItemListEvent => {
  return {
    event: 'view_item_list',
    ecommerce: {
      items: items?.map((item, idx) =>
        makeItem({
          product: item.product,
          productItem: item.productItem,
          itemIndex: idx,
          listId: item.listId,
          listName: item.listName,
        })
      ),
    },
  }
}
