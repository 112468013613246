exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-kit-tsx": () => import("./../../../src/pages/[slug]/kit.tsx" /* webpackChunkName: "component---src-pages-[slug]-kit-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-order-id-tsx": () => import("./../../../src/pages/account/order/[...id].tsx" /* webpackChunkName: "component---src-pages-account-order-id-tsx" */),
  "component---src-pages-account-store-order-id-tsx": () => import("./../../../src/pages/account/store-order/[...id].tsx" /* webpackChunkName: "component---src-pages-account-store-order-id-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account/[...].tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-aplicativo-[slug]-tsx": () => import("./../../../src/pages/aplicativo/[slug].tsx" /* webpackChunkName: "component---src-pages-aplicativo-[slug]-tsx" */),
  "component---src-pages-aplicativo-download-tsx": () => import("./../../../src/pages/aplicativo/download.tsx" /* webpackChunkName: "component---src-pages-aplicativo-download-tsx" */),
  "component---src-pages-aplicativo-index-tsx": () => import("./../../../src/pages/aplicativo/index.tsx" /* webpackChunkName: "component---src-pages-aplicativo-index-tsx" */),
  "component---src-pages-atendimento-tsx": () => import("./../../../src/pages/atendimento.tsx" /* webpackChunkName: "component---src-pages-atendimento-tsx" */),
  "component---src-pages-avalie-o-produto-index-tsx": () => import("./../../../src/pages/avalie-o-produto/index.tsx" /* webpackChunkName: "component---src-pages-avalie-o-produto-index-tsx" */),
  "component---src-pages-cadastro-tsx": () => import("./../../../src/pages/cadastro.tsx" /* webpackChunkName: "component---src-pages-cadastro-tsx" */),
  "component---src-pages-campanha-[slug]-tsx": () => import("./../../../src/pages/campanha/[slug].tsx" /* webpackChunkName: "component---src-pages-campanha-[slug]-tsx" */),
  "component---src-pages-campanha-black-friday-tsx": () => import("./../../../src/pages/campanha/black-friday.tsx" /* webpackChunkName: "component---src-pages-campanha-black-friday-tsx" */),
  "component---src-pages-campanha-bon-tsx": () => import("./../../../src/pages/campanha/bon.tsx" /* webpackChunkName: "component---src-pages-campanha-bon-tsx" */),
  "component---src-pages-campanha-colecao-beachwear-2022-tsx": () => import("./../../../src/pages/campanha/colecao-beachwear-2022.tsx" /* webpackChunkName: "component---src-pages-campanha-colecao-beachwear-2022-tsx" */),
  "component---src-pages-campanha-colecao-fitness-2022-tsx": () => import("./../../../src/pages/campanha/colecao-fitness-2022.tsx" /* webpackChunkName: "component---src-pages-campanha-colecao-fitness-2022-tsx" */),
  "component---src-pages-campanha-dia-das-criancas-tsx": () => import("./../../../src/pages/campanha/dia-das-criancas.tsx" /* webpackChunkName: "component---src-pages-campanha-dia-das-criancas-tsx" */),
  "component---src-pages-campanha-eco-concepcao-yoga-tsx": () => import("./../../../src/pages/campanha/eco-concepcao-yoga.tsx" /* webpackChunkName: "component---src-pages-campanha-eco-concepcao-yoga-tsx" */),
  "component---src-pages-campanha-ecodesign-montanha-tsx": () => import("./../../../src/pages/campanha/ecodesign-montanha.tsx" /* webpackChunkName: "component---src-pages-campanha-ecodesign-montanha-tsx" */),
  "component---src-pages-campanha-ecodesign-tsx": () => import("./../../../src/pages/campanha/ecodesign.tsx" /* webpackChunkName: "component---src-pages-campanha-ecodesign-tsx" */),
  "component---src-pages-campanha-inverno-tsx": () => import("./../../../src/pages/campanha/inverno.tsx" /* webpackChunkName: "component---src-pages-campanha-inverno-tsx" */),
  "component---src-pages-campanha-natal-components-banner-natal-tsx": () => import("./../../../src/pages/campanha/natal/components/BannerNatal.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-banner-natal-tsx" */),
  "component---src-pages-campanha-natal-components-benefits-carousel-tsx": () => import("./../../../src/pages/campanha/natal/components/BenefitsCarousel.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-benefits-carousel-tsx" */),
  "component---src-pages-campanha-natal-components-gifts-carousel-tsx": () => import("./../../../src/pages/campanha/natal/components/GiftsCarousel.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-gifts-carousel-tsx" */),
  "component---src-pages-campanha-natal-components-gifts-price-tsx": () => import("./../../../src/pages/campanha/natal/components/GiftsPrice.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-gifts-price-tsx" */),
  "component---src-pages-campanha-natal-components-index-tsx": () => import("./../../../src/pages/campanha/natal/components/index.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-index-tsx" */),
  "component---src-pages-campanha-natal-components-single-banner-tsx": () => import("./../../../src/pages/campanha/natal/components/SingleBanner.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-single-banner-tsx" */),
  "component---src-pages-campanha-natal-components-single-carousel-tsx": () => import("./../../../src/pages/campanha/natal/components/SingleCarousel.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-single-carousel-tsx" */),
  "component---src-pages-campanha-natal-components-slick-slide-index-tsx": () => import("./../../../src/pages/campanha/natal/components/SlickSlide/index.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-slick-slide-index-tsx" */),
  "component---src-pages-campanha-natal-components-slick-slide-slick-slide-tsx": () => import("./../../../src/pages/campanha/natal/components/SlickSlide/SlickSlide.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-slick-slide-slick-slide-tsx" */),
  "component---src-pages-campanha-natal-components-two-banners-tsx": () => import("./../../../src/pages/campanha/natal/components/TwoBanners.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-components-two-banners-tsx" */),
  "component---src-pages-campanha-natal-index-tsx": () => import("./../../../src/pages/campanha/natal/index.tsx" /* webpackChunkName: "component---src-pages-campanha-natal-index-tsx" */),
  "component---src-pages-campanha-sandever-born-for-beach-tennis-tsx": () => import("./../../../src/pages/campanha/sandever-born-for-beach-tennis.tsx" /* webpackChunkName: "component---src-pages-campanha-sandever-born-for-beach-tennis-tsx" */),
  "component---src-pages-cashback-index-tsx": () => import("./../../../src/pages/cashback/index.tsx" /* webpackChunkName: "component---src-pages-cashback-index-tsx" */),
  "component---src-pages-checkout-cart-index-tsx": () => import("./../../../src/pages/checkout/cart/index.tsx" /* webpackChunkName: "component---src-pages-checkout-cart-index-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-circula-index-tsx": () => import("./../../../src/pages/circula/index.tsx" /* webpackChunkName: "component---src-pages-circula-index-tsx" */),
  "component---src-pages-circula-produtos-tsx": () => import("./../../../src/pages/circula/produtos.tsx" /* webpackChunkName: "component---src-pages-circula-produtos-tsx" */),
  "component---src-pages-clube-cadastro-tsx": () => import("./../../../src/pages/clube/cadastro.tsx" /* webpackChunkName: "component---src-pages-clube-cadastro-tsx" */),
  "component---src-pages-clube-garantia-tsx": () => import("./../../../src/pages/clube/garantia.tsx" /* webpackChunkName: "component---src-pages-clube-garantia-tsx" */),
  "component---src-pages-clube-index-tsx": () => import("./../../../src/pages/clube/index.tsx" /* webpackChunkName: "component---src-pages-clube-index-tsx" */),
  "component---src-pages-clube-parceiros-tsx": () => import("./../../../src/pages/clube/parceiros.tsx" /* webpackChunkName: "component---src-pages-clube-parceiros-tsx" */),
  "component---src-pages-clube-regulamento-tsx": () => import("./../../../src/pages/clube/regulamento.tsx" /* webpackChunkName: "component---src-pages-clube-regulamento-tsx" */),
  "component---src-pages-clube-servicos-tsx": () => import("./../../../src/pages/clube/servicos.tsx" /* webpackChunkName: "component---src-pages-clube-servicos-tsx" */),
  "component---src-pages-colecao-dia-dos-pais-decathlon-2022-index-tsx": () => import("./../../../src/pages/colecao/dia-dos-pais-decathlon-2022/index.tsx" /* webpackChunkName: "component---src-pages-colecao-dia-dos-pais-decathlon-2022-index-tsx" */),
  "component---src-pages-colecao-tsx": () => import("./../../../src/pages/colecao.tsx" /* webpackChunkName: "component---src-pages-colecao-tsx" */),
  "component---src-pages-collection-tsx": () => import("./../../../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-esporte-[slug]-tsx": () => import("./../../../src/pages/esporte/[slug].tsx" /* webpackChunkName: "component---src-pages-esporte-[slug]-tsx" */),
  "component---src-pages-esportes-[slug]-tsx": () => import("./../../../src/pages/esportes/[slug].tsx" /* webpackChunkName: "component---src-pages-esportes-[slug]-tsx" */),
  "component---src-pages-guia-de-tamanhos-tsx": () => import("./../../../src/pages/guia-de-tamanhos.tsx" /* webpackChunkName: "component---src-pages-guia-de-tamanhos-tsx" */),
  "component---src-pages-header-login-tsx": () => import("./../../../src/pages/header-login.tsx" /* webpackChunkName: "component---src-pages-header-login-tsx" */),
  "component---src-pages-header-tsx": () => import("./../../../src/pages/header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institucional-[slug]-tsx": () => import("./../../../src/pages/institucional/[slug].tsx" /* webpackChunkName: "component---src-pages-institucional-[slug]-tsx" */),
  "component---src-pages-institucional-fundacao-decathlon-tsx": () => import("./../../../src/pages/institucional/fundacao-decathlon.tsx" /* webpackChunkName: "component---src-pages-institucional-fundacao-decathlon-tsx" */),
  "component---src-pages-institucional-quem-somos-tsx": () => import("./../../../src/pages/institucional/quem-somos.tsx" /* webpackChunkName: "component---src-pages-institucional-quem-somos-tsx" */),
  "component---src-pages-institucional-quem-somos-v-2-tsx": () => import("./../../../src/pages/institucional/quem-somos-v2.tsx" /* webpackChunkName: "component---src-pages-institucional-quem-somos-v-2-tsx" */),
  "component---src-pages-institucional-recrutamento-fornecedores-tsx": () => import("./../../../src/pages/institucional/recrutamento-fornecedores.tsx" /* webpackChunkName: "component---src-pages-institucional-recrutamento-fornecedores-tsx" */),
  "component---src-pages-institucional-sustentabilidade-brasil-ambiental-tsx": () => import("./../../../src/pages/institucional/sustentabilidade-brasil/ambiental.tsx" /* webpackChunkName: "component---src-pages-institucional-sustentabilidade-brasil-ambiental-tsx" */),
  "component---src-pages-institucional-sustentabilidade-brasil-governanca-tsx": () => import("./../../../src/pages/institucional/sustentabilidade-brasil/governanca.tsx" /* webpackChunkName: "component---src-pages-institucional-sustentabilidade-brasil-governanca-tsx" */),
  "component---src-pages-institucional-sustentabilidade-brasil-social-tsx": () => import("./../../../src/pages/institucional/sustentabilidade-brasil/social.tsx" /* webpackChunkName: "component---src-pages-institucional-sustentabilidade-brasil-social-tsx" */),
  "component---src-pages-institucional-sustentabilidade-brasil-tsx": () => import("./../../../src/pages/institucional/sustentabilidade-brasil.tsx" /* webpackChunkName: "component---src-pages-institucional-sustentabilidade-brasil-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-lojas-[slug]-tsx": () => import("./../../../src/pages/lojas/[slug].tsx" /* webpackChunkName: "component---src-pages-lojas-[slug]-tsx" */),
  "component---src-pages-lojas-tsx": () => import("./../../../src/pages/lojas.tsx" /* webpackChunkName: "component---src-pages-lojas-tsx" */),
  "component---src-pages-lp-[slug]-tsx": () => import("./../../../src/pages/lp/[slug].tsx" /* webpackChunkName: "component---src-pages-lp-[slug]-tsx" */),
  "component---src-pages-marcas-[slug]-tsx": () => import("./../../../src/pages/marcas/[slug].tsx" /* webpackChunkName: "component---src-pages-marcas-[slug]-tsx" */),
  "component---src-pages-marcas-tsx": () => import("./../../../src/pages/marcas.tsx" /* webpackChunkName: "component---src-pages-marcas-tsx" */),
  "component---src-pages-marketplace-[slug]-tsx": () => import("./../../../src/pages/marketplace/[slug].tsx" /* webpackChunkName: "component---src-pages-marketplace-[slug]-tsx" */),
  "component---src-pages-marketplace-cadastro-tsx": () => import("./../../../src/pages/marketplace/cadastro.tsx" /* webpackChunkName: "component---src-pages-marketplace-cadastro-tsx" */),
  "component---src-pages-marketplace-index-tsx": () => import("./../../../src/pages/marketplace/index.tsx" /* webpackChunkName: "component---src-pages-marketplace-index-tsx" */),
  "component---src-pages-marketplace-triagem-tsx": () => import("./../../../src/pages/marketplace/triagem.tsx" /* webpackChunkName: "component---src-pages-marketplace-triagem-tsx" */),
  "component---src-pages-member-authorize-index-tsx": () => import("./../../../src/pages/member/authorize/index.tsx" /* webpackChunkName: "component---src-pages-member-authorize-index-tsx" */),
  "component---src-pages-member-update-index-tsx": () => import("./../../../src/pages/member/update/index.tsx" /* webpackChunkName: "component---src-pages-member-update-index-tsx" */),
  "component---src-pages-pesquisa-tsx": () => import("./../../../src/pages/pesquisa.tsx" /* webpackChunkName: "component---src-pages-pesquisa-tsx" */),
  "component---src-pages-product-manufacturer-code-store-id-index-tsx": () => import("./../../../src/pages/product/[manufacturerCode]/[storeId]/index.tsx" /* webpackChunkName: "component---src-pages-product-manufacturer-code-store-id-index-tsx" */),
  "component---src-pages-produtosecoconcepcao-tsx": () => import("./../../../src/pages/produtosecoconcepcao.tsx" /* webpackChunkName: "component---src-pages-produtosecoconcepcao-tsx" */),
  "component---src-pages-sacolasreutilizaveis-tsx": () => import("./../../../src/pages/sacolasreutilizaveis.tsx" /* webpackChunkName: "component---src-pages-sacolasreutilizaveis-tsx" */),
  "component---src-pages-servicos-assessoria-imprensa-tsx": () => import("./../../../src/pages/servicos/assessoria-imprensa.tsx" /* webpackChunkName: "component---src-pages-servicos-assessoria-imprensa-tsx" */),
  "component---src-pages-servicos-campanha-de-qualidade-tsx": () => import("./../../../src/pages/servicos/campanha-de-qualidade.tsx" /* webpackChunkName: "component---src-pages-servicos-campanha-de-qualidade-tsx" */),
  "component---src-pages-servicos-cartao-presente-tsx": () => import("./../../../src/pages/servicos/cartao-presente.tsx" /* webpackChunkName: "component---src-pages-servicos-cartao-presente-tsx" */),
  "component---src-pages-servicos-cupom-de-desconto-tsx": () => import("./../../../src/pages/servicos/cupom-de-desconto.tsx" /* webpackChunkName: "component---src-pages-servicos-cupom-de-desconto-tsx" */),
  "component---src-pages-servicos-decathlon-circula-tsx": () => import("./../../../src/pages/servicos/decathlon-circula.tsx" /* webpackChunkName: "component---src-pages-servicos-decathlon-circula-tsx" */),
  "component---src-pages-servicos-formas-de-pagamento-tsx": () => import("./../../../src/pages/servicos/formas-de-pagamento.tsx" /* webpackChunkName: "component---src-pages-servicos-formas-de-pagamento-tsx" */),
  "component---src-pages-servicos-garantia-vitalicia-tsx": () => import("./../../../src/pages/servicos/garantia-vitalicia.tsx" /* webpackChunkName: "component---src-pages-servicos-garantia-vitalicia-tsx" */),
  "component---src-pages-servicos-politica-de-entrega-tsx": () => import("./../../../src/pages/servicos/politica-de-entrega.tsx" /* webpackChunkName: "component---src-pages-servicos-politica-de-entrega-tsx" */),
  "component---src-pages-servicos-politica-de-privacidade-tsx": () => import("./../../../src/pages/servicos/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-servicos-politica-de-privacidade-tsx" */),
  "component---src-pages-servicos-recall-tsx": () => import("./../../../src/pages/servicos/recall.tsx" /* webpackChunkName: "component---src-pages-servicos-recall-tsx" */),
  "component---src-pages-servicos-registre-sua-bicicleta-tsx": () => import("./../../../src/pages/servicos/registre-sua-bicicleta.tsx" /* webpackChunkName: "component---src-pages-servicos-registre-sua-bicicleta-tsx" */),
  "component---src-pages-servicos-regras-de-promocoes-tsx": () => import("./../../../src/pages/servicos/regras-de-promocoes.tsx" /* webpackChunkName: "component---src-pages-servicos-regras-de-promocoes-tsx" */),
  "component---src-pages-servicos-retire-na-loja-tsx": () => import("./../../../src/pages/servicos/retire-na-loja.tsx" /* webpackChunkName: "component---src-pages-servicos-retire-na-loja-tsx" */),
  "component---src-pages-servicos-trocas-e-devolucoes-tsx": () => import("./../../../src/pages/servicos/trocas-e-devolucoes.tsx" /* webpackChunkName: "component---src-pages-servicos-trocas-e-devolucoes-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */),
  "component---src-pages-servicos-vale-troca-tsx": () => import("./../../../src/pages/servicos/vale-troca.tsx" /* webpackChunkName: "component---src-pages-servicos-vale-troca-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-treino-em-casa-tsx": () => import("./../../../src/pages/treino-em-casa.tsx" /* webpackChunkName: "component---src-pages-treino-em-casa-tsx" */)
}

